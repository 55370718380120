import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./scss/app.scss";
import reportWebVitals from "./reportWebVitals";
import { api_root, homepage } from "./conf";

declare global {
  interface Window {
    ns: {
      register: (type: string, id: string, rid: string) => void;
      unregister: (id: string, rid: string) => void;
      setup: (opts: any) => void;
    };
  }
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

const App = React.lazy(() => {
  if (process.env.REACT_APP_BUILD_TARGET === "dashboard") {
    return import("./DashboardApp");
  } else if (process.env.REACT_APP_BUILD_TARGET === "minerva") {
    return import("./ChatApp");
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
});

const Ns = () => {
  useEffect(() => {
    const tag = document.head.appendChild(document.createElement("script"));

    tag.onload = () => {
      window.ns.setup({ apiUrl: api_root });
    };

    tag.src =
      process.env.NODE_ENV === "development"
        ? "https://novem.no/s/ns.js"
        : `${homepage}/s/ns.js`;
    return () => tag.remove();
  }, []);

  return null;
};

root.render(
  <React.StrictMode>
    <Ns />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
